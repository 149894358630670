<template>
  <div class="video-view">
    <video class="video-con" controls>
      <source src="https://toyspace-prod.oss-cn-beijing.aliyuncs.com/public/mini/demo-video/TOY-pc.mp4" poster="https://toyspace-prod.oss-cn-beijing.aliyuncs.com/public/mini/demo-video/TOY-pc.mp4?x-oss-process=video/snapshot,t_1000,w_0,h_0,m_fast,ar_auto" type="video/mp4">
    </video>
  </div>
  
</template>

<script>
export default {
  name: "video",
  data() {
    return {
      
    };
  },
  mounted() {
    
  },
  methods: {
    
  },
};
</script>
<style lang="scss">
  .video-view{
    width: 100%;
    height: 100vh;
    background: #000;
    display: flex;
    align-items: center;
    .video-con{
      width: 100%;
      height: 100%;
    }
  }
</style>
